$primary: #00629E;
$secondary: #526070;
$info: #009EB9;
$success: #056D2F;
$warning: #CEA727;
$danger: #BA1A1A;
$light: #FCFCFF;
$dark: #1A1C1E;

@import "bootstrap/scss/bootstrap.scss";
